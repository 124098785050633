import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Libraries
import { map as _map, filter as _filter, indexOf as _indexOf } from 'lodash'

// Components
import {
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
  BannerDefault,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Posts from 'components/Posts'

// Language
import { getLanguage } from 'services/language'

// CSS
import 'styles/templates/BlogsTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        banner_title
        banner_subtitle
        banner_text

        filterTitle: filter_title
        filterNoResultsTitle: no_results_title
        filterNoResultsSubtitle: no_results_subtitle
        filterLoadMoreTitle: load_more_title

        postReadMoreTitle: post_load_more_button
      }
      yoast_meta {
        name
        content
        property
      }
    }

    categoriesNL: allWordpressWpCategorieen(
      sort: { fields: acf___sortnumber, order: ASC }
      filter: { wpml_current_locale: { eq: "nl_NL" } }
    ) {
      ...categoriesContent
    }

    categoriesEN: allWordpressWpCategorieen(
      sort: { fields: acf___sortnumber, order: ASC }
      filter: { wpml_current_locale: { eq: "en_US" } }
    ) {
      ...categoriesContent
    }
  }

  fragment categoriesContent on wordpress__wp_categorieenConnection {
    edges {
      node {
        id: wordpress_id
        title
      }
    }
  }
`

function Blogs({ data: { page, categoriesNL, categoriesEN } }) {
  const language = getLanguage()
  const categoriesContent = language === 'nl_NL' ? categoriesNL : categoriesEN

  const [filters, setFilters] = useState({
    selected: [],
  })

  return (
    <Layout backgroundImage={page.acf.backgroundImage}>
      {page.acf.backgroundImage && page.acf.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: page.yoast_meta }}
          pathname={page.path}
          image={page.acf.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="blogs-template">
        <BannerDefault className="py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <TitleAlt>{page.acf.banner_title}</TitleAlt>
              <TitleDefault asH1 className="pl-lg-5">
                {page.acf.banner_subtitle}
              </TitleDefault>
              <div className="pr-lg-5 mr-lg-5">
                <ContentDefault className="mt-4">
                  <div
                    dangerouslySetInnerHTML={{ __html: page.acf.banner_text }}
                  />
                </ContentDefault>
              </div>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="container mt-2">
          <TitleSubDefault>{page.acf.filterTitle}</TitleSubDefault>
          <div className="blogs-template-categories">
            {_map(categoriesContent.edges, ({ node: { id, title } }) => {
              const active = _indexOf(filters.selected, id) !== -1

              return (
                <button
                  key={id}
                  type="button"
                  onClick={() => {
                    let newSelected = filters.selected
                    const index = _indexOf(newSelected, id)

                    if (index === -1) newSelected.push(id)
                    else
                      newSelected = _filter(
                        newSelected,
                        (select, newIndex) => newIndex !== index
                      )

                    return setFilters({
                      ...filters,
                      ...{ selected: newSelected },
                    })
                  }}
                >
                  <BoxDefault
                    className={`py-1 px-3 mr-4 mt-3${
                      active ? ` box-active` : ``
                    }`}
                  >
                    <TitleSubDefault className="py-2 px-4">
                      {title}
                    </TitleSubDefault>
                  </BoxDefault>
                </button>
              )
            })}
          </div>
          <div className="mt-4 pt-3">
            <Posts
              categories={filters.selected}
              limit={4}
              showHasMore
              text={{
                filterNoResultsTitle: page.acf.filterNoResultsTitle,
                filterNoResultsSubtitle: page.acf.filterNoResultsSubtitle,
                filterLoadMoreTitle: page.acf.filterLoadMoreTitle,
                postReadMoreTitle: page.acf.postReadMoreTitle,
              }}
              data={{
                categories: categoriesContent.edges,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blogs
